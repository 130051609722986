<template>
  <div>
    <card class="form-horizontal">
      <h4 slot="header" class="card-title">Production General</h4>

      <div class="row">
        <label class="col-sm-2 col-form-label">Name</label>
        <div class="col-sm-7">
          <fg-input
            type="text"
            name="production_name"
            v-validate="modelValidations.production_name"
            :error="getError('production_name')"
            v-model="model.production_name"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Code</label>
        <div class="col-sm-4">
          <fg-input
            type="text"
            name="production_code"
            v-validate="modelValidations.production_code"
            :error="getError('production_code')"
            v-model="model.production_code"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Parent Production</label>
        <div class="col-sm-7">
          <el-select
            class="select-default"
            placeholder="Parent Production"
            v-model="model.parent_production_id"
            v-bind:class="{ 'select-default': !errors.has('parent_production_id'), 'select-danger': errors.has('parent_production_id') }"
            name="parent_production_id"
            v-validate="modelValidations.parent_production_id"
            :error="getError('parent_production_id')"
          >
            <el-option
              v-for="option in parentProductions"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Organiser</label>
        <div class="col-sm-7">
          <fg-input
            type="text"
            name="organisername"
            v-validate="modelValidations.organisername"
            :error="getError('organisername')"
            v-model="model.organisername"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Forms Close</label>
        <div class="col-sm-7">
          <el-date-picker
            v-model="model.production_forms_close"
            type="datetime"
            placeholder="Select date and time"
          ></el-date-picker>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Cut Off Date</label>
        <div class="col-sm-7">
          <el-date-picker
            v-model="model.cut_off_date"
            type="datetime"
            placeholder="Select date and time"
          ></el-date-picker>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Status</label>
        <div class="col-sm-7">
          <el-select
            class="select-default"
            size="large"
            placeholder="Status"
            v-model="model.production_status"
          >
            <el-option
              v-for="option in productionStatus"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Currency</label>
        <div class="col-sm-7">
          <el-select
            class="select-default"
            size="large"
            placeholder="Status"
            v-model="model.ccy_id"
          >
            <el-option
              v-for="option in ccyId"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Production Email</label>
        <div class="col-sm-7">
          <fg-input
            type="text"
            name="productionemail"
            v-validate="modelValidations.productionemail"
            :error="getError('productionemail')"
            v-model="model.productionemail"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Listing Email</label>
        <div class="col-sm-7">
          <fg-input
            type="text"
            name="listingemail"
            v-validate="modelValidations.listingemail"
            :error="getError('listingemail')"
            v-model="model.listingemail"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Show Website URL</label>
        <div class="col-sm-7">
          <fg-input
            type="text"
            name="production_url"
            v-validate="modelValidations.production_url"
            :error="getError('production_url')"
            v-model="model.production_url"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Max Words in Text Entry</label>
        <div class="col-sm-4">
          <fg-input
            type="number"
            name="production_max_words"
            v-validate="modelValidations.production_max_words"
            :error="getError('production_max_words')"
            v-model="model.production_max_words"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Max Number of Products</label>
        <div class="col-sm-4">
          <fg-input
            type="number"
            name="production_max_products"
            v-validate="modelValidations.production_max_products"
            :error="getError('production_max_products')"
            v-model="model.production_max_products"
          ></fg-input>
        </div>
      </div>

       <div class="row">
        <label class="col-sm-2 col-form-label">Display Advertising</label>
        <div class="col-sm-7">
          <el-select
            class="select-default"
            size="large"
            placeholder="Status"
            v-model="model.display_advertising"
          >
            <el-option
              v-for="option in AdvertisingValues"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </div>
      </div>


      <div class="row">
        <label class="col-sm-2 col-form-label">Display Areas of Operation</label>
        <div class="col-sm-7">
          <el-select
            class="select-default"
            size="large"
            placeholder="Status"
            v-model="model.display_areasofoperation"
          >
            <el-option
              v-for="option in YesNo"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Max Areas of Operation</label>
        <div class="col-sm-4">
          <fg-input
            type="number"
            name="production_max_areasofoperation"
            v-validate="{ rules: { required: this.maxAreasOfOperation }, numeric: true, min_value: 0 } "
            :error="getError('production_max_areasofoperation')"
            v-model="model.production_max_areasofoperation"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Display Brands</label>
        <div class="col-sm-7">
          <el-select
            class="select-default"
            size="large"
            placeholder="Status"
            v-model="model.display_brands"
          >
            <el-option
              v-for="option in YesNo"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </div>
      </div>


      <!-- { rules: { required: this.maxBrandsRequired } }

  v-validate="modelValidations.production_max_brands"
      -->
      <div class="row">
        <label class="col-sm-2 col-form-label">Max Brands</label>
        <div class="col-sm-4">
          <fg-input
            type="number"
            name="production_max_brands"
            v-validate="{ rules: { required: this.maxBrandsRequired }, numeric: true, min_value: 0 } "
            :error="getError('production_max_brands')"
            v-model="model.production_max_brands"
          ></fg-input>
        </div>
      </div>

       <div class="row">
        <label class="col-sm-2 col-form-label">Enable multi-language</label>
        <div class="col-sm-7">
          <el-select
            class="select-default"
            size="large"
            placeholder="Status"
            v-model="model.enable_multi_lng"
          >
            <el-option
              v-for="option in YesNo"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="row imagerow">
        <label class="col-sm-2 col-form-label">Production Bitmap</label>
        <div class="col-sm-4">

          <picture-input
            ref="productionBitmapInput"
            width="300"
            height="300"
            v-bind:prefill="productionImageUrl"
            accept="image/gif, image/jpeg, image/png"
            size="10"
            :hideChangeButton="true"
            button-class="btn"
            @change="afterCompleteProductionImage"
          ></picture-input>
        </div>
      </div>
      <div class="row fatrow">
        <label class="col-sm-2 col-form-label"></label>
        <div class="col-md-2">
          <p-button
            type="info"
            @click.prevent="uploadProductionImage"
            :disabled="isImageUploadDisabled"
          >Upload Image</p-button>
        </div>
      </div>

      <hr>
      <h3>Production Accounts</h3>

      <div class="row">
        <label class="col-sm-2 col-form-label">Accounts Email</label>
        <div class="col-sm-7">
          <fg-input
            type="text"
            name="accountsemail"
            v-validate="modelValidations.accountsemail"
            :error="getError('accountsemail')"
            v-model="model.accountsemail"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Payment Options</label>
        <div class="col-sm-7">
          <el-select
            class="select-default"
            size="large"
            placeholder
            v-model="model.payment_options"
          >
            <el-option
              v-for="option in PaymentOptions"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Credit Card Threshold</label>
        <div class="col-sm-4">
          <fg-input
            type="text"
            name="cc_threshold"
            v-validate="modelValidations.cc_threshold"
            :error="getError('cc_threshold')"
            v-model="model.cc_threshold"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Nominal Code</label>
        <div class="col-sm-4">
          <fg-input
            type="text"
            name="nominalcode"
            v-validate="modelValidations.nominalcode"
            :error="getError('nominalcode')"
            v-model="model.nominalcode"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Project Code</label>
        <div class="col-sm-4">
          <fg-input
            type="text"
            name="projectcode"
            v-validate="modelValidations.projectcode"
            :error="getError('projectcode')"
            v-model="model.projectcode"
          ></fg-input>
        </div>
      </div>

      <hr>
      <h3>Production Sales</h3>

      <div class="row">
        <label class="col-sm-2 col-form-label">Sales Email</label>
        <div class="col-sm-7">
          <fg-input
            type="text"
            name="salesemail"
            v-validate="modelValidations.salesemail"
            :error="getError('salesemail')"
            v-model="model.salesemail"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Sales Telephone</label>
        <div class="col-sm-6">
          <fg-input
            type="text"
            name="salestel"
            v-validate="modelValidations.salestel"
            :error="getError('salestel')"
            v-model="model.salestel"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Free Online Logo</label>
        <div class="col-sm-7">
          <el-select class="select-default" size="large" placeholder v-model="model.freeonlinelogo">
            <el-option
              v-for="option in YesNo"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Revenue Target</label>
        <div class="col-sm-4">
          <fg-input
            type="text"
            name="rev_target"
            v-validate="modelValidations.rev_target"
            :error="getError('rev_target')"
            v-model="model.rev_target"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Number of Pages</label>
        <div class="col-sm-4">
          <fg-input
            type="number"
            name="number_of_pages"
            v-validate="modelValidations.number_of_pages"
            :error="getError('number_of_pages')"
            v-model="model.number_of_pages"
          ></fg-input>
        </div>
      </div>

      <hr>
      <h3>Production Source Data API</h3>

      <div class="row">
        <label class="col-sm-2 col-form-label">API URL</label>
        <div class="col-sm-9">
          <fg-input
            type="text"
            name="apiurl"
            v-validate="modelValidations.apiurl"
            :error="getError('apiurl')"
            v-model="model.apiurl"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">API User</label>
        <div class="col-sm-9">
          <fg-input
            type="text"
            name="api_user"
            v-validate="modelValidations.api_user"
            :error="getError('api_user')"
            v-model="model.api_user"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">API Password</label>
        <div class="col-sm-9">
          <fg-input
            type="text"
            name="api_password"
            v-validate="modelValidations.api_password"
            :error="getError('api_password')"
            v-model="model.api_password"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">API Key</label>
        <div class="col-sm-7">
          <fg-input
            type="text"
            name="apikey"
            v-validate="modelValidations.apikey"
            :error="getError('apikey')"
            v-model="model.apikey"
          ></fg-input>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Organiser</label>
        <div class="col-sm-7">
          <el-select
            class="select-default"
            size="large"
            @change="APIselected"
            placeholder
            v-model="model.organiser_api"
          >
            <el-option
              v-for="option in organiserApi"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Hint:</label>
        <div class="col-sm-7">{{apiInfo}}</div>
      </div>

      <div class="row">
        <!-- <label class="col-md-3"></label> -->
        <div class="col-md-9">
          <p-button type="info" @click.prevent="validate">Save</p-button>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import * as CONSTS from "../../../../../consts/constants";

import { Card } from "src/components/UIComponents";
import swal from "sweetalert2";
import PictureInput from 'vue-picture-input'


import {
  DatePicker,
  TimeSelect,
  Slider,
  Tag,
  Input,
  Button,
  Select,
  Option
} from "element-ui";

// create a dictionary for localised labels in forms
const dictionary = {
  en: {
    attributes: {
      production_name: "Production name",
      production_code: "Production code",
      organisername: "Organiser name",
      productionemail: "Production email",
      listingemail: "Listing email",
      production_url: "Production Website URL",
      production_max_words: "Production maximum words",
      production_max_products: "Production maximum products",
      display_areasofoperation: "Display Areas of Operation",
      production_max_areasofoperation: "Max Areas of Operation",
      production_max_brands: "Max brands",
      accountsemail: "Accounts email",
      cc_threshold: "credit card threshold",
      nominalcode: "Nominal Code",
      projectcode: "Project Code",
      salestel: "Sales Telephone",
      rev_target: "revenue target",
      number_of_pages: "number of pages",
      apikey: "API Key",
      apiurl: "API URL",
      api_user: "API User",
      api_password: "API Password",
      production_bitmap_shortform: "production bitmap (new)"
    }
  }
};

export default {
  computed: {
    maxBrandsRequired() {
      return this.model.display_brands ? true : false;
    },
    maxAreasOfOperation() {
      return this.model.display_areasofoperation ? true : false;
    },
    isImageUploadDisabled() {
        return !this.imageAvailableForUpload
        // return this.form.validated;
      }
  },
  data() {
    return {
      mode: CONSTS.UPDATE,

      productionbitmapupload: null,  // image for upload
      imageAvailableForUpload: false,
      productionImageUrl:'',

      id: "",
      parentProductions: [],
      productionStatus: CONSTS.strings.production_status_label,
      ccyId: CONSTS.strings.ccy_id_label,
      YesNo: CONSTS.strings.no_yes_label,
      AdvertisingValues: CONSTS.strings.advertising_label,
      organiserApi: CONSTS.strings.organiser_api,
      PaymentOptions: CONSTS.strings.payment_options_label,
      apiInfo: "",
      model: {
        // general
        production_name: "",
        production_status: 1,
        production_code: "",
        production_forms_close: "",
        cut_off_date: "",
        // accounts
        nominalcode: 0,
        accountsemail: "",
        projectcode: 0,
        // production

        //sales
        rev_target: 0,
        rev_total: 0,
        salesemail: "",
        salestel: "",
        cost_per_product: 0,
        cost_per_word: 0,
        free_logo: 0,
        freeonlinelogo: 0,
        page_yield: 0,
        payment_options: 0,
        price_order: 0,

        ad_status: 0,
        additional_help_text: "",
        additional_product_text: 0,
        aoo2_title: "",
        aoo3_title: "",
        aoo_title: "",
        bookingformxml: "",
        cc_threshold: 0,
        ccy_id: 1,
        change_notification_email: "",
        company_title: "",

        details_extra_page: "",
        disable_company_name: 0,
        disable_unpaid_sharers: 0,
        display_areasofoperation: 0,
        display_areasofoperation2: 0,
        display_areasofoperation3: 0,
        display_brands: 0,
        display_advertising: 0,
        display_countryoforigin: 0,
        display_details: 0,
        display_main_product: 0,
        display_products: 0,
        display_profile_flash: 0,
        display_text: 0,
        display_text2: 0,
        enable_change_notification: 0,
        enable_ezone: 1,

        hide_aoo_number: 0,
        hide_product_number: 0,
        hide_stand: 0,
        hqaddress: 0,
        listingemail: "",
        listingtel: "",
        loginhost: "",
        logo_height: 0,
        logo_width: 0,

        number_of_pages: 0,
        onsite_contact: 0,
        organisername: "",

        parent_production_id: null,

        prod_logo: "",
        production_adds_page: "",
        production_bitmap: "",
        production_bitmap_large: "",

        production_directory_bitmap: "",
        production_enhanced_page: "",
        production_extras_bitmap: "",
        production_extras_page: "",

        production_id: 0,
        production_max_areasofoperation: 0,
        production_max_areasofoperation2: 0,
        production_max_areasofoperation3: 0,
        production_max_brands: 0,
        production_max_contacts: 0,
        production_max_credits: 0,
        production_max_products: 0,
        production_max_words: 0,

        production_type: "",
        production_url: "",
        productionemail: "",
        products_title: "",

        publisher_contact: "",
        publisher_email: "",
        publisher_tel: "",

        text_entry_title: "",
        webstatus: 0,

        apiurl: "",
        apikey: "",
        api_user: "",
        api_password: "",
        organiser_api: "",
        production_bitmap_shortform: "",
        enable_multi_lng: 0
      },
      modelValidations: {
        production_name: {
          required: true,
          max: 200
        },
        production_code: {
          required: true,
          max: 20
        },
        organisername: {
          required: true,
          max: 50
        },
        ccy_id: {
          required: true
        },
        productionemail: {
          required: true,
          email: true,
          max: 50
        },
        salesemail: {
          required: true,
          email: true,
          max: 50
        },
        listingemail: {
          required: true,
          email: true,
          max: 50
        },
        accountsemail: {
          required: true,
          email: true,
          max: 50
        },
        production_url: {
          required: true,
          url: true,
          max: 100
        },
        production_max_words: {
          required: true,
          numeric: true,
          min_value: 0
        },
        production_max_products: {
          required: true,
          numeric: true,
          min_value: 0
        },
        production_max_areasofoperation: {
          numeric: true,
          min_value: 0
        },
        production_max_brands: {
          rules: {
            required: this.maxBrandsRequired
          },
          numeric: true,
          min_value: 0
        },
        number_of_pages: {
          numeric: true,
          required: true,
          min_value: 1
        },
        cc_threshold: {
          decimal: 2,
          min_value: 0
        },
        rev_target: {
          decimal: 2,
          required: true,
          min_value: 1
        },
        nominalcode: {
          max: 4
        },
        projectcode: {
          max: 4
        },
        salestel: {
          required: true,
          max: 50
        },
        parent_production_id: {
          required: true
        },
        idSource: {
          required: true
        },
        idDestination: {
          required: true,
          confirmed: "idSource"
        },
        apiurl: {
          max: 120
        },
        apikey: {
          max: 80
        },
        api_user: {
          max: 80
        },
        api_password: {
          max: 80
        }
      }
    };
  },
  created() {
    if (this.$route.params.id === undefined) {
      console.log("new prod");
      this.mode = CONSTS.NEW;
    } else {
      this.id = this.$route.params.id;
      this.mode = CONSTS.UPDATE;
    }
    // set up a dictionary for the EN form labels
    this.$validator.localize(dictionary);
  },
  mounted() {
    // main record

    if (this.mode == CONSTS.UPDATE) {
      this.$http
        .get("common/production/" + this.id)
        .then(response => {
          if (
            response.data.hasOwnProperty("apiresponse") &&
            response.data.apiresponse.status !== "success"
          ) {
            this.$notify({
              message:
                "There was a problem loading the production. Please try again. [1]",
              type: "warning"
            });
          } else {
            this.model = response.data

            if (this.model.production_bitmap_shortform !== null && String(this.model.production_bitmap_shortform).length > 0){
                this.productionImageUrl = 'https://www.fizzzmedia.com/images/fizzz/'+this.model.production_bitmap_shortform
              } else {
                this.productionImageUrl = null
              }
          }
        })
        .catch(error => {
          console.log(error);
          this.$notify({
            message:
              "There was a problem loading the production. Please try again. [2]",
            type: "warning"
          });
        });
    }

    // parent productions
    this.$http
      .get("common/parentproduction")
      .then(response => (this.parentProductions = response.data))
      .catch(error => {
        // console.log(error)
        this.$notify({
          message:
            "There was a problem loading the parent productions. Please try again. [3]",
          type: "warning"
        });
      });
  },
  components: {
    Card,
    PictureInput,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },



    afterCompleteProductionImage(image){



        let obj = this.$refs.productionBitmapInput

        if (this.$refs.productionBitmapInput.file) {


          this.productionbitmapupload = this.$refs.productionBitmapInput.file;
          this.imageAvailableForUpload = true



        } else {
          console.log("Old browser. No support for Filereader API");
        }
      },

      uploadProductionImage(){

        const fd = new FormData();
        let vm = this

        fd.append('image', this.productionbitmapupload, this.productionbitmapupload.name)

        this.$http.post( '/image/upload', fd )
            .then(function (response) {

              if (response.data.hasOwnProperty("apiresponse") && response.data.apiresponse.status !== 'success'){
                vm.$notify({
                  message: 'There was a problem uploading the image. Please try again.',
                  type: 'fatal'
                })
              } else {
                vm.model.production_bitmap_shortform = response.data.uploadedfile

                vm.$notify({
                  message: "The image was uploaded. Don't forget to save the Production!",
                  type: 'success',
                  timeout: 10000,
                  horizontalAlign: 'center'
                })
              }
            })
            .catch(function (error) {
              console.log(error)
              vm.$notify({
                message: 'There was a problem uploading the image. Please try again.',
                type: 'fatal'
              })
            })
      },

    updateProduction() {
      let vm = this;

      // convert the JS ISO date to mysql datetime
      this.model.production_forms_close = this.$moment(
        this.model.production_forms_close
      ).format("YYYY-MM-DD HH:mm:ss");
      this.model.cut_off_date = this.$moment(this.model.cut_off_date).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      this.$http
        .put("common/production/" + this.id, this.model)
        .then(function(response) {
          if (response.data.apiresponse.status !== "success") {
            vm.$notify({
              message:
                "There was a problem saving the production. Please try again. Error code: " +
                response.data.apiresponse.code,
              type: "warning"
            });
          } else {
            vm.$notify({
              message: "Production saved",
              type: "success"
            });
          }
        })
        .catch(function(error) {
          console.log(error);
          vm.$notify({
            message:
              "There was a problem saving the production. Please try again.",
            type: "warning"
          });
        });
    },

    insertProduction() {
      let vm = this;

      // convert the JS ISO date to mysql datetime
      this.model.production_forms_close = this.$moment(
        this.model.production_forms_close
      ).format("YYYY-MM-DD HH:mm:ss");
      this.model.cut_off_date = this.$moment(this.model.cut_off_date).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      this.$http
        .post("common/production", this.model)
        .then(function(response) {
          if (response.data.apiresponse.status !== "success") {
            vm.$notify({
              message:
                "There was a problem saving the production. Please try again. Error code: " +
                response.data.apiresponse.code,
              type: "warning"
            });
          } else {
            console.log(response.data.apiresponse.keys.guid);

            vm.id = response.data.apiresponse.keys.guid;
            vm.mode = CONSTS.UPDATE;

            vm.$notify({
              message: "Production saved",
              type: "success"
            });
          }
        })
        .catch(function(error) {
          console.log(error);
          vm.$notify({
            message:
              "There was a problem saving the production. Please try again.",
            type: "warning"
          });
        });
    },

    APIselected() {
      switch (this.model.organiser_api) {
        case "REEDS":
          this.apiInfo =
            "The URL must take this form: https://v2-eibtm.rxnova.com/api/v2_3/eventEditions/6657";
          break;
        case "INFORMA":
          this.apiInfo = "";
          break;
      }
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          if (this.mode == CONSTS.UPDATE) {
            this.updateProduction();
          } else {
            this.insertProduction();
          }
        } else {
          this.$notify({
            message: "Please correct the validation issues and resubmit.",
            type: "warning"
          });
        }

        //this.$emit('on-submit', this.registerForm, isValid)
      });
    }
  }
};
</script>
<style scoped>
div.row {
  height: 60px;
}

div.imagerow { height: 300px; }
 div.fatrow { height: 60px; }

.divider div {
  height: 25px; /* 30% of viewport height*/
}
</style>

